import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/coffeescript-jquery",
  "date": "2015-01-29",
  "title": "COFFEESCRIPT – JQUERY",
  "author": "admin",
  "tags": ["development", "javascript", "jquery", "coffeescript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Writing jQuery in CoffeeScript, further simplifies the syntax. The jQuery(function($)) can be written as jQuery($) -> or simply $ ->."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Take the following example for an instance:`}</p>
    <p><strong parentName="p">{`JavaScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`jQuery(function($) {
    function changeTab(e) {
    e.preventDefault();
    $("#tabs li a.active").removeClass("active");
    $(this).addClass("active");
    $("#tabs ul li a").click(changeTab);
});
`}</code></pre>
    <p>{`Here, the `}<strong parentName="p">{`function changeTab(e)`}</strong>{` becomes `}<strong parentName="p">{`changeTab = (e) ->`}</strong>{`. And you can remove all semicolons and curly brackets.
You can also optionally remove parenthesis as well.`}</p>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$ ->
    changeTab = (e) ->
        e.preventDefault()
        $("#tabs li a.active").removeClass "active"
        $(this).addClass "active" // or, $(@).addClass "active"
        
    $("#tabs ul li a").click changeTab
`}</code></pre>
    <p>{`Now let's try to convert following jQuery code into CoffeeScript:`}</p>
    <p><strong parentName="p">{`JavaScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function showNumberOfFlights(e) {
    var num_flights = $(this).data('flights');
    $(this).append("<span>" + num_flights +"\`");
    $("#tabs span.tooltip").show();
}
`}</code></pre>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`showNumberOfFlights = (e) ->
    num_flights = $(@).data 'flights'
    $(@).append "<span>#{flights}\`"
    $("#tabs span.tooltip").show()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      